<template>
	<div class="overflow-auto h-screen">
		<div class="bg-gray-darkest w-full overflow-hidden flex flex-col">
			<div 
				class="w-full h-full flex flex-col overflow-y-auto "
				style="background: radial-gradient(50% 50% at 50% 90%, #666 0%, rgba(0, 0, 0, 0) 100%);">
				
				<div class="flex-none w-full px-8">
					<div class="container max-w-6xl mx-auto my-4">
						<div class="md:flex items-center w-full mb-8 md:mb-0">
							<div class="flex-grow w-full flex justify-center md:justify-start my-12 md:my-6">
								<img 
									src="/assets/logo.svg" 
								/>
							</div>
							
							<div class="flex items-center justify-center space-x-6">
								<router-link 
									class="button dark w-40" 
									to="signin">
									
									Login
								</router-link>
								
								<router-link 
									class="button submit w-40 font-bold uppercase" 
									style="text-shadow: 0 0 3px rgba(0,0,0,.3)"
									to="signup">
									
									Kom i gang
								</router-link>
							</div>
						</div>
					</div>
				</div>
				
				<div class="flex-none mx-auto text-center max-w-2xl my-12 md:text-lg px-8">
					<p class="text-white mb-0">
						Den ulimate løsningen for mersalg og oppsalg
					</p>
					<h1 class="font-bold text-4xl md:text-6xl text-center text-white mb-4 leading-none">
						Motiver dine ansatte
					</h1>
					<p class="text-gray-light">
						La dine ansatte bruke Upsell for å få oversikt over kampanjer, 
						varer og tjenester, priser og målsetning rett på mobil og nettbrett.
						Sett opp periode-mål for avdelinger og premiér ansattes innsats!
					</p>
				</div>
				
				<div class="flex-grow flex items-end mx-auto text-center">
					<img 
						src="/assets/mobile_frontpage.png" 
						alt="Mobilephone showing Upsell application"
						style="max-height: 60vh;"
					/>
				</div>
			</div>
		</div>
		
		<div class="bg-beige-dark overflow-hidden p-12">
			<div class="max-w-screen-lg mx-auto my-12 md:grid grid-cols-2 gap-12">
				<div>
					<h3>
						Ta Steget mot Suksess med Upsell 
					</h3>
					<p>
						- Ditt Digitale Verktøy for Økt Salg og Lønnsomhet!
						I en verden hvor konkurranse er intens, er det nødvendig å tenke smart for å oppnå suksess. Velkommen til Upsell - din digitale billett til økt salg, motiverte medarbeidere og en eksplosjon av lønnsomhet for din bedrift!
					</p>
				</div>
				<div>
					<h3>
						Hva er Upsell?
					</h3>
					<p>
						Det er ikke bare et program; det er en revolusjon i måten vi driver forretning på. Upsell går utover tradisjonell salgsstrategi ved å tilby en skreddersydd tilnærming som gir din bedrift den konkurransefordelen den trenger. Uansett din rolle i bedriften, har du en nøkkel til suksess i Upsell!
					</p>
				</div>
				
				<div>
					<h3>
						Økt Salg, Økt Glede
					</h3>
					<p>
						Upsell gir deg verktøyene du trenger for å krydre salgsprosessen. Enten du er i frontlinjen eller styrer strategien, vil du oppdage at Upsell gir deg muligheten til å tilby kundene dine mer enn de forventer. Resultatet? Økt salg som overstiger alle forventninger!					</p>
				</div>
				<div>
					<h3>
						Motiverte Medarbeidere, Produktiv Arbeidsplass
					</h3>
					<p>
						Upsell er ikke bare for salgsteamet. Det er for alle i organisasjonen som ønsker å se bedriften vokse og blomstre. Gjennom motiverende trening og brukervennlige verktøy, gir Upsell din arbeidsstyrke selvtillit og ferdigheter som vil løfte dem til nye høyder.
					</p>
				</div>
				
				<div>
					<h3>
						Økt Lønnsomhet, Bærekraftig Vekst
					</h3>
					<p>
						Hva er målet med enhver bedrift? Å oppnå bærekraftig vekst og økt lønnsomhet. Upsell er nøkkelen til å låse opp denne døren. Ved å optimalisere salgsprosesser, forbedre kundetilfredshet og styrke interne strukturer, baner Upsell vei for bærekraftig suksess.
					</p>
				</div>
				<div>
					<h3>
						Din Rolle, Din Suksesshistorie
					</h3>
					<p>
						Uansett om du er leder, selger, markedsfører eller en nøkkelperson i produksjonen, har du en viktig rolle i Upsell-revolusjonen. Ved å delta aktivt, kan du forme din suksesshistorie og være en del av en bedriftskultur som går ut over forventningene.
					</p>
				</div>
			</div>
			
			<div class="max-w-screen-md mx-auto mt-24 mb-12 text-center">
				<p>
					Så, hva venter du på? Ta det første skrittet mot suksess, og la Upsell være din guide. Økt salg, motiverte medarbeidere og økt lønnsomhet er innen rekkevidde. Bli med på reisen mot en fremtid hvor din bedrift ikke bare overlever, men trives!
				</p>
				<p>
					Upsell - fordi suksess ikke er en tilfeldighet, det er en strategi.
				</p>
			</div>
		</div>
		
		<div class="bg-beige overflow-hidden px-12">
			<div class="max-w-screen-lg mx-auto my-12 md:grid grid-cols-2 gap-12">
				<div 
					v-for="(testimonal, index) in testimonals"
					:key="'testimonal_'+index"
					class="bg-white p-12 text-center">
					
					<div class="mb-4 inline-block">
						<svg width="115" height="19" viewBox="0 0 115 19" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M81.5 0L83.6329 6.56434H90.535L84.9511 10.6213L87.084 17.1857L81.5 13.1287L75.916 17.1857L78.0489 10.6213L72.465 6.56434H79.3671L81.5 0Z" fill="#2EBD59"/>
							<path d="M33.5 0L35.6329 6.56434H42.535L36.9511 10.6213L39.084 17.1857L33.5 13.1287L27.916 17.1857L30.0489 10.6213L24.465 6.56434H31.3671L33.5 0Z" fill="#2EBD59"/>
							<path d="M105.5 0L107.633 6.56434H114.535L108.951 10.6213L111.084 17.1857L105.5 13.1287L99.916 17.1857L102.049 10.6213L96.465 6.56434H103.367L105.5 0Z" fill="#2EBD59"/>
							<path d="M57.5 0L59.6329 6.56434H66.535L60.9511 10.6213L63.084 17.1857L57.5 13.1287L51.916 17.1857L54.0489 10.6213L48.465 6.56434H55.3671L57.5 0Z" fill="#2EBD59"/>
							<path d="M9.5 0L11.6329 6.56434H18.535L12.9511 10.6213L15.084 17.1857L9.5 13.1287L3.91604 17.1857L6.04892 10.6213L0.464963 6.56434H7.36712L9.5 0Z" fill="#2EBD59"/>
						</svg>
					</div>

					<p>
						{{ testimonal.text }}
					</p>
					<h3>
						{{ testimonal.name }}
					</h3>
					<div>
						{{ testimonal.title }}
					</div>
					<div>
						{{ testimonal.company }}
					</div>
				</div>
			</div>
		</div>
		
		
		<div class="bg-beige overflow-hidden px-12">
			<div class="max-w-screen-lg mx-auto my-12 text-center">
				Kontakt oss på epost <a href="mailto:salg@upsell.no">salg@upsell.no</a> eller ring oss på telefonnummer 97 67 12 64, for å komme i gang!
			</div>
		</div>
		
		
		<div 
			class="p-12 text-center text-black"
			@click="onRefreshAll()">
			
			Version {{version}} 
			
			<i class="fas fa-sync-alt ml-2" />
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				testimonals: [
					{
						text: `«Det å bli påminnet hvilket fokus vi har i bedriften, har økt salgskulturen i hele organisasjonen! Ikke bare i salgsavdelingen.»`,
						name: 'Janne Johansen',
						title: 'General Manager ',
						company: 'Quality Hotel™ 33',
					},
					{
						text: `«Å se mine egne og resten av gruppas tall "live", trigger konkurranseinstinktet, og gjør det mye lettere å legge inn ekstragiret!»`,
						name: 'Jan Helge Hartweg Hongslo',
						title: 'Distriktsansvarlig Selger',
						company: 'Nor Tekstil AS',
					},
					{
						text: `«Nor Tekstil AS og jeg kan allerede etter to måneder se at resultatene øker! Vi har ønsket å sette fokus på aktivitet og resultater gjennom bruk av Upsell. Upsell virker som et fantastisk verktøy!»`,
						name: 'Thomas Egelund',
						title: 'Nasjonal Salgssjef',
						company: 'Nor Tekstil AS',
					},
					{
						text: `«Etter å ha brukt Upsell i over et år ser vi økt fokus på mersalg og oppsalg hos våre ansatte, noe som igjen har løftet salget på vårt hotell. At de ansatte til enhver tid kan følge med på salgstallene er også en stor fordel. Vi kan på det sterkeste anbefale Upsell.»`,
						name: 'Aina Aalen Bjerkvik',
						title: 'Hotelldirektør/General Manager',
						company: 'Quality Hotel Waterfront',
					}
				],
			}
		},
		
		methods: {
			onRefreshAll(){
				if ('serviceWorker' in navigator) {
					navigator.serviceWorker.getRegistrations().then( registrations => {
						for(let registration of registrations) {
							registration.unregister()
						}
					});
				}
				
				location.reload();
			},
		}
	}
</script>


<style lang="scss" scoped>
	h3 {
		@apply text-black;
	}
</style>

